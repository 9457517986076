import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import type { Editor } from 'tinymce';
import type { FormatMessage } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import { getExternalVideoConsentCookie } from '../../components/common/CookieBanner/CookieHelper';
import {
  getVideoThumbnailUrl,
  getVideoUrlFromIframe
} from '../../components/messages/VideoPreviewHelper/VideoPreviewHelper';
import {
  DATA_MEDIA,
  DATA_MEDIA_ALIGN,
  DATA_MEDIA_CAPTION,
  DATA_MEDIA_LAYOUT,
  DATA_MEDIA_RESIZED,
  DATA_MEDIA_SIZE,
  DATA_VIDEO_CONTAINER,
  getMediaClass,
  getImgElement,
  getLoadingIndicator,
  ImageLayout,
  isImageFigure,
  isFigCaption,
  MediaSize,
  setLayout,
  getContainerElementWidth
} from './EditorMediaHelper';
import {
  AlignmentOptions,
  ContentAlign,
  CustomEditorButton,
  insertContentWithParagraph,
  setCursorAtEndOfNode
} from './EditorHelper';
import { DATA_MCE_BOGUS } from './TinyMceInternalHelper';

const LI_VIDEO = 'li-video';
const DATA_VIDEO_ID = 'data-lia-video-vid';

const log = getLog(module);
const IFRAME = 'iframe';

export const VIDEO_PROCESSING_INDICATOR = 'lia-video-processing-indicator';
export const DATA_VIDEO_STATUS = 'data-lia-video-status';
export const DATA_MEDIA_TITLE = 'data-lia-video-title';

export const DATA_VIDEO_THUMBNAIL = 'data-lia-video-thumbnail';
const VIDEO_PLAYER = 'lia-video-player';

export enum UPLOAD_STATUS {
  LIVE = 'live',
  UPLOADING = 'uploading'
}

export const audioFileTypes = ['m4a', 'mp3', 'wma', 'aac', 'wav'];

export interface VideoData {
  /**
   * ID of video.
   */
  remoteVid?: string;
  /**
   * ID of the video from brightcove.
   */
  vid: string;
  /**
   * The src url of the video.
   */
  src?: string;
  /**
   * The alternative text for the video.
   */
  alt?: string;
  /**
   * The video size.
   */
  size?: string;
  /**
   * The alignment of the video.
   */
  align?: string;
  /**
   * The thumbnail url of the video.
   */
  thumbnailUrl?: string;
  /**
   * The source of the video, whether url or from computer.
   */
  external?: string;
  /**
   * The title  of the video.
   */
  title?: string;
  /**
   * The description of the video.
   */
  description?: string;
  /**
   * The layout of the video.
   */
  layout?: string;
  /**
   * Whether the video has been resized (true for the free-form resize case).
   */
  resized?: string;
  /**
   * The video caption.
   */
  caption?: string;
}

/**
 * Whether the element is a video container or not.
 *
 * @param element the element.
 */
function isVideoFigure(element: Element): boolean {
  return element?.hasAttribute('data-lia-video-container');
}

/**
 * Whether the element has the `lia-video-overlay` span or not.
 *
 * @param element the element.
 */
function hasVideoOverlay(element: Element): boolean {
  return !!element.querySelector('.lia-video-overlay');
}

/**
 * Get the video container by id.
 *
 * @param editor the TinyMCE Editor.
 * @param id the video id.
 */
function getVideoContainerById(editor: Editor, id: string): HTMLElement | null {
  return editor.getBody()?.querySelector(`[${DATA_VIDEO_ID}="${id}"]`);
}

/**
 * Removes the video container element from the editor.
 *
 * @param editor active TinyMCE editor.
 * @param id id of the video.
 */
function removeVideoContainer(editor: Editor, id: string): void {
  getVideoContainerById(editor, id).remove();
  // Remove the resize options lying in the DOM
  const toxPopList = [...document.querySelectorAll('.tox-pop')];
  toxPopList.map(element => element.remove());
  editor.selection.setCursorLocation(editor.getBody().lastChild, 0);
}

/**
 * Get the video player placeholder node which would be replaced with an actual video player.
 *
 * @param editor the Editor.
 * @param videoId the video ID.
 */
function getVideoPlayerPlaceholder(editor: Editor, videoId: string): HTMLElement {
  return getVideoContainerById(editor, videoId)?.querySelector(
    '[data-lia-video-player-placeholder]'
  );
}

/**
 * checks whether a file is audio file.
 * @param fileTitle the file title
 */
export function isAudioFile(fileTitle: string): boolean {
  const fileExtension: string = fileTitle?.split('.').pop();
  return audioFileTypes.includes(fileExtension);
}

/**
 * Creates an iframe HTML for the given values.
 *
 * @param source the source.
 * @param title the title.
 * @param cssClass the CSS class to apply to the iframe container.
 */
function createIframeHtml(source: string, title: string, cssClass: string): string {
  return `<iframe class="lia-video-external ${cssClass}" ${DATA_MEDIA} src="${source}"
            title="${title}" frameborder="0" allowfullscreen>
          </iframe>`;
}

/**
 * Creates a HTML markup to render the cookie consent banner
 *
 * @param editorCx editor styles.
 * @param videoUrl the video url
 * @param formatMessage localizes message
 * @param iframe the video iframe
 * @param size the video element size
 */
function createThumbailPreviewWithBanner(
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  videoUrl: string = '',
  iframe: string = '',
  size: string = ''
): string {
  const bannerClass = editorCx('lia-cookie-banner', {
    'lia-cookie-banner-small': size === MediaSize.SMALL
  });
  const containerClass = editorCx('lia-cookie-banner-container');
  const textClass = editorCx('mb-0');
  const buttonClass = editorCx('lia-cookie-consent-button', {
    'lia-g-mt-5': size === MediaSize.SMALL
  });
  const buttonText = formatMessage('buttonTitle');
  const thumbnailUrl = getVideoThumbnailUrl(videoUrl, iframe);
  const videoLink = `<a href="${getVideoUrlFromIframe(iframe)}"
    class="${editorCx('lia-external-url')}"
    target="_blank" rel="noreferrer noopener">${formatMessage('urlText')}</a>`;
  const bannerText = formatMessage('bannerTitle', { url: videoLink });

  return thumbnailUrl
    ? `<div class="${containerClass}" >
            <img src="${thumbnailUrl}" alt="" />
            <div class="${bannerClass}">
                <p class="${textClass}">${bannerText}</p>
                <button class="${buttonClass}">${buttonText}</button>
            </div>
       </div>`
    : '';
}

/**
 * Create a video container for the given values which looks like:
 *
 * <div contenteditable="false">
 *   <iframe> </iframe>
 *   <span> </span>
 * </div>
 *
 * @param videoData the video data.
 * @param formatMessage localizes message.
 * @param editorCx editor styles.
 * @param cssClass the CSS class to apply to the video container.
 * @param videoUrl the video url
 * @param iframeSourceUrl the video iframe source url
 * @param isExternalVideoCookieBannerEnabled whether or not the config to display external video cookie banner is enabled
 */
export function createVideoContainerForIframe(
  videoData: VideoData,
  formatMessage: FormatMessage,
  editorCx: ClassNamesFnWrapper,
  cssClass = '',
  videoUrl?: string,
  iframeSourceUrl?: string,
  isExternalVideoCookieBannerEnabled?: boolean
): string {
  const figureClass = editorCx('lia-figure');
  const figcaptionClass = editorCx('lia-image-caption');
  const placeholderAttr = `data-lia-placeholder="${formatMessage('mediaCaptionPlaceholder')}"`;

  const {
    vid,
    title = '',
    src,
    thumbnailUrl = '',
    align = ContentAlign.CENTER,
    size = MediaSize.LARGE,
    resized = 'false',
    layout = ImageLayout.STRETCH,
    caption = ''
  } = videoData;

  const mediaSizeClass = getMediaClass('size', size);
  const mediaAlignClass = getMediaClass('is', align);
  const sourceUrl = src ?? iframeSourceUrl;
  const _html =
    isExternalVideoCookieBannerEnabled && !getExternalVideoConsentCookie()
      ? createThumbailPreviewWithBanner(
          editorCx,
          formatMessage,
          videoUrl,
          createIframeHtml(sourceUrl, title, cssClass),
          size
        )
      : src
      ? createIframeHtml(src, title, cssClass)
      : `<div ${DATA_MEDIA} class=${cssClass}></div>`;

  return `<figure contenteditable="false"
                class="${figureClass} ${IFRAME} ${mediaSizeClass} ${mediaAlignClass}"
                data-lia-video-container data-lia-video-vid="${vid}" data-lia-video-src="${sourceUrl}"
                data-lia-video-title="${title}" ${DATA_MEDIA_SIZE}="${size}" ${DATA_MEDIA_RESIZED}="${resized}"
                ${DATA_MEDIA_ALIGN}="${align}" ${DATA_MEDIA_LAYOUT}="${layout}"
                data-lia-video-external="url" data-lia-video-thumbnail="${thumbnailUrl}">
              ${_html}
              <figcaption contenteditable="true" class="${figcaptionClass}" ${placeholderAttr} ${DATA_MEDIA_CAPTION}>${caption}</figcaption>
              <span class="lia-video-overlay"></span>
          </figure>`;
}

/**
 * Adds video processing indicator only if the video is in loading state.
 * @param editorCx editor styles
 * @param loadingCx local styles
 * @param processingIndicatorClass processing indicator styles
 * @param isLoading where the video is being loaded.
 */
function getVideoProcessingIndicator(
  editorCx: ClassNamesFnWrapper,
  loadingCx: ClassNamesFnWrapper,
  processingIndicatorClass: string,
  isLoading: boolean
): string {
  return isLoading
    ? `<div class="${processingIndicatorClass}">${getLoadingIndicator(editorCx, loadingCx)}</div>`
    : '';
}

/**
 * Create a video container for the given values which looks like:
 *
 * <div contenteditable="false">
 *   <div> video icon player </div>
 * </div>
 *
 * @param videoData the video data.
 * @param status the video status.
 * @param editorCx editor styles.
 * @param isLoading whether the upload of video is done or not.
 * @param formatMessage localizes message.
 * @param loadingCx local styles
 */
const createVideoContainer = (
  videoData: VideoData,
  status: string,
  isLoading: boolean,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  loadingCx: ClassNamesFnWrapper
): string => {
  const videoPlayerIconClass = editorCx('lia-video-player-icon');
  const figureClass = editorCx('lia-figure');
  const figcaptionClass = editorCx('lia-image-caption');
  const placeholderAttr = `data-lia-placeholder="${formatMessage('mediaCaptionPlaceholder')}"`;
  const videoPlayer = editorCx(VIDEO_PLAYER);

  const {
    remoteVid,
    vid,
    src,
    align = ContentAlign.CENTER,
    size = 'default',
    resized = 'false',
    layout = ImageLayout.STRETCH,
    alt = '',
    title = '',
    caption = ''
  } = videoData;
  const imgHtml = src
    ? `<img ${DATA_MEDIA} class="${videoPlayer}" src="${src}" alt="${alt}" />`
    : `<div ${DATA_MEDIA} class="${videoPlayer}"></div>`;

  const mediaSizeClass = getMediaClass('size', size);
  const mediaAlignClass = getMediaClass('is', align);

  return `<figure contenteditable="false" class="${figureClass} ${IFRAME} ${mediaSizeClass} ${mediaAlignClass}"
            data-lia-video-container data-lia-video-vid="${vid}" data-lia-video-remote-vid="${remoteVid}"
            ${DATA_MEDIA_SIZE}="${size}" ${DATA_MEDIA_RESIZED}="${resized}" ${DATA_MEDIA_ALIGN}="${align}"
            ${DATA_MEDIA_LAYOUT}="${layout}" ${DATA_VIDEO_STATUS}="${status}" data-lia-video-title="${title}">
            <div class="lia-video-wrapper">
            ${getVideoProcessingIndicator(
              editorCx,
              loadingCx,
              VIDEO_PROCESSING_INDICATOR,
              isLoading
            )}
            ${imgHtml}
            <span class="${videoPlayerIconClass}" data-lia-video-player-placeholder></span>
            <span class="lia-video-overlay"></span>
            </div>
            <figcaption contenteditable="true" class="${figcaptionClass}" ${placeholderAttr} ${DATA_MEDIA_CAPTION}>${caption}</figcaption>
          </figure>`;
};

/**
 * Create the video placeholder for video.
 *
 * @param videoId the video id.
 * @param editorCx editor styles.
 * @param formatMessage localizes message.
 * @param file The uploaded video file.
 * @param loadingCx local styles.
 */
async function createVideoMarkupPromises(
  videoId: string,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  file: File,
  loadingCx: ClassNamesFnWrapper
): Promise<string> {
  try {
    return createVideoContainer(
      {
        vid: videoId,
        src: '',
        alt: file.name,
        title: file.name
      },
      'uploading',
      true,
      editorCx,
      formatMessage,
      loadingCx
    );
  } catch (error) {
    log.error(error, 'Error in creating video container markup');
  }
  return '';
}

/**
 * Sets the layout CSS class and it's corresponding data attribute to the `figure` element based on the video's width.
 */
function setVideoLayout(editor: Editor): void {
  editor
    .getBody()
    .querySelectorAll<HTMLElement>(`[${DATA_VIDEO_CONTAINER}]`)
    .forEach(figureElement => {
      const videoElement = getImgElement(figureElement);
      const containerWidth = getContainerElementWidth(videoElement, editor);
      setLayout(figureElement, videoElement.offsetWidth, containerWidth);
    });
}

/**
 * Insert video container placeholder in the RTE.
 *
 * @param editor the Editor.
 * @param videoId the video ID.
 * @param editorCx editor styles.
 * @param formatMessage localizes message.
 * @param videoUrl the video url
 * @param iframeSourceUrl the video iframe source url
 * @param isExternalVideoCookieBannerEnabled whether or not the config to display external video cookie banner is enabled
 */
function insertVideoPlaceholder(
  editor: Editor,
  videoId: string,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  videoUrl?: string,
  iframeSourceUrl?: string,
  isExternalVideoCookieBannerEnabled?: boolean
): void {
  const videoContainer = createVideoContainerForIframe(
    {
      vid: videoId
    },
    formatMessage,
    editorCx,
    VIDEO_PLAYER,
    videoUrl,
    iframeSourceUrl,
    isExternalVideoCookieBannerEnabled
  );
  insertContentWithParagraph(editor, videoContainer);
}

/**
 * Inserts the video markup from a list of video promises result.
 *
 * @param videoPromises the list of video promises.
 * @param editor the TinyMCE Editor.
 */
function insertVideoFromPromises(
  videoPromises: PromiseSettledResult<string>[],
  editor: Editor
): PromiseSettledResult<string>[] {
  const markup = videoPromises
    .filter(value => value.status === 'fulfilled')
    .map(value => (value as PromiseFulfilledResult<string> | undefined)?.value)
    .join('');
  const lastImageNode = editor.selection.getNode();
  if (isImageFigure(lastImageNode) || isVideoFigure(lastImageNode)) {
    const wrapper = editor.dom.create('p');
    lastImageNode.after(wrapper);
    editor.selection.setCursorLocation(wrapper, 0);
  }
  insertContentWithParagraph(editor, markup);
  setVideoLayout(editor);
  editor.selection.getNode().scrollIntoView();
  return videoPromises;
}

/**
 * Updates the temporary Id to videoId after video upload.
 *
 * @param tempId the temporary video Id
 * @param referenceVideoId the video id from brightcove.
 * @param videoId video Id after video upload.
 * @param editor TinyMCE Editor.
 */
function updateVideoContainer(
  tempId: string,
  referenceVideoId: string,
  videoId: string,
  editor: Editor
): void {
  const videoContainerPlaceholder = getVideoContainerById(editor, tempId);
  videoContainerPlaceholder?.setAttribute('data-lia-video-vid', videoId);
  videoContainerPlaceholder?.setAttribute('data-lia-video-remote-vid', referenceVideoId);
}

/**
 * Update the video upload status to processing.
 *
 * @param videoId the video id.
 * @param editor TinyMCE Editor.
 * @param editorCx editor styles.
 * @param formatMessage localizes message.
 */
function updateVideoIndicators(
  videoId: string,
  editor: Editor,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage
): void {
  const videoContainer = getVideoContainerById(editor, videoId);
  const finalisingIndicatorClass = 'lia-video-upload-finalise-indicator';
  const finalisingVideoPlaceholderText = formatMessage('finalisingVideoPlaceholder');
  const videoLoadingIndicator = videoContainer?.querySelector(
    `.${editorCx('lia-image-loading-wrapper')}`
  );
  if (videoLoadingIndicator) {
    videoLoadingIndicator.outerHTML = `<div class="${finalisingIndicatorClass}">${finalisingVideoPlaceholderText}</div>`;
  }
}
/**
 * Remove the processsing video indicator from the RTE.
 *
 * @param videoId the video ID.
 * @param editor the Editor.
 * @param editorCx the editor styles.
 * @param formatMessage localizes message.
 * @param isVideoLive lookup video status where LIVE or not
 * @param thumbnailUrl video url
 */
function updateProcessingVideoIndicator(
  videoId: string,
  editor: Editor,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  isVideoLive?: boolean,
  thumbnailUrl?: string
): void {
  const videoContainer: HTMLElement = document?.body?.querySelector(
    `[${DATA_VIDEO_ID}="${videoId}"]`
  ) as HTMLElement;
  const title = videoContainer?.getAttribute(DATA_MEDIA_TITLE);
  const ifAudioFile = title ? isAudioFile(title) : false;
  const thumbnail = ifAudioFile ? null : thumbnailUrl;
  const processingIndicatorClass = 'lia-video-upload-finalise-indicator';
  const videoLoadingIndicator = videoContainer?.querySelector(
    `.${editorCx('lia-video-upload-finalise-indicator')}`
  );
  const processingVideoPlaceholderText = formatMessage('processingVideoPlaceholder');
  if (videoLoadingIndicator) {
    videoLoadingIndicator.outerHTML = `<div class="${processingIndicatorClass}">${processingVideoPlaceholderText}</div>`;
  }
  //when video is live
  if (isVideoLive && videoContainer) {
    videoContainer.querySelector('.' + VIDEO_PROCESSING_INDICATOR)?.remove();
    videoContainer.setAttribute(DATA_VIDEO_STATUS, UPLOAD_STATUS.LIVE);
    videoContainer.setAttribute(DATA_VIDEO_THUMBNAIL, thumbnail);
    const mediaDiv = videoContainer.querySelector(`[${DATA_MEDIA}]`) as HTMLElement;
    const videoPlayer = editorCx(VIDEO_PLAYER);
    if (thumbnail) {
      mediaDiv.outerHTML = `<img ${DATA_MEDIA} class="${videoPlayer}" src="${thumbnail}" />`;
    }
    editor.save();
  }
}

/**
 * Update the video data after the media snippet query response is received.
 *
 * @param editor the TinyMCE Editor.
 * @param videoId the video ID.
 * @param mediaSnippet the media snippet response.
 */
function updateVideoContainerForIframe(editor: Editor, videoId: string, mediaSnippet): void {
  const { thumbnail } = mediaSnippet;
  const parser = window.tinymce.html.DomParser(editor.editorManager.defaultOptions, editor.schema);
  const parsedContent = parser.parse(mediaSnippet.displayContent);
  const iframeNode = parsedContent.firstChild;
  const iframeSource = iframeNode.attr('src');
  const iframeTitle = iframeNode.attr('title');

  const videoContainerPlaceholder = getVideoContainerById(editor, videoId);
  const iframeContainerPlaceholder = getImgElement(videoContainerPlaceholder);
  const iframeHtml = createIframeHtml(iframeSource, iframeTitle, '');
  iframeContainerPlaceholder.outerHTML = iframeHtml;
  videoContainerPlaceholder.dataset.liaVideoTitle = iframeTitle;
  videoContainerPlaceholder.dataset.liaVideoSrc = iframeSource;
  videoContainerPlaceholder.dataset.liaVideoThumbnail = thumbnail[0].url;
  editor.save();
  const currentNode = editor.selection.getNode();
  setCursorAtEndOfNode(editor, currentNode);
  currentNode.scrollIntoView();
}

/**
 * Register a Node filter to convert `li-video` tag to corresponding HTML.
 *
 * @param editor TinyMCE Editor.
 * @param formatMessage localizes message.
 * @param editorCx editor styles.
 * @param loadingCx local styles.
 * @param updateSourceForMediaElementCallback callback to update src if not present.
 * @param updateThumbnail callback to update the thumbnail for videos.
 * @param isExternalVideoCookieBannerEnabled whether or not the config to display external video cookie banner is enabled
 */
function registerXmlToHtmlConverter(
  editor: Editor,
  formatMessage: FormatMessage,
  editorCx: ClassNamesFnWrapper,
  loadingCx: ClassNamesFnWrapper,
  updateSourceForMediaElementCallback: (element: Element, videoInfo: VideoData) => void,
  updateThumbnail: (vid: string) => Promise<string>,
  isExternalVideoCookieBannerEnabled?: boolean
): void {
  editor.parser.addNodeFilter(LI_VIDEO, () => {
    setTimeout(() => {
      editor
        .getBody()
        .querySelectorAll(LI_VIDEO)
        .forEach(element => {
          if (element.getAttribute('external') === 'url') {
            const videoData = {
              vid: element.getAttribute('vid'),
              title: element.getAttribute('title'),
              src: element.getAttribute('src'),
              thumbnailUrl: element.getAttribute('thumbnail'),
              external: element.getAttribute('external'),
              align: element.getAttribute('align'),
              layout: element.getAttribute('layout'),
              size: element.getAttribute('size'),
              resized: element.getAttribute('resized'),
              caption: element.innerHTML
            };
            if (!videoData.src) {
              updateSourceForMediaElementCallback(element, videoData);
            } else {
              const videoUrl = videoData.vid;
              element.outerHTML = createVideoContainerForIframe(
                videoData,
                formatMessage,
                editorCx,
                '',
                videoUrl,
                null,
                isExternalVideoCookieBannerEnabled
              );
            }
          } else {
            const videoId = element.getAttribute('vid');
            const remoteVid = element.getAttribute('remotevid');
            const videoData = {
              remoteVid,
              vid: videoId,
              caption: element.innerHTML,
              src: element.getAttribute('src'),
              align: element.getAttribute('align'),
              layout: element.getAttribute('layout'),
              size: element.getAttribute('size'),
              resized: element.getAttribute('resized'),
              alt: element.getAttribute('title'),
              title: element.getAttribute('title'),
              thumbnailUrl: element.getAttribute('thumbnail'),
              status: element.getAttribute('status')
            };
            if (!videoData.src && !isAudioFile(videoData.title)) {
              //for migrated videos remoteVid and vid are same and equal
              const videoIdNew =
                videoData.remoteVid === 'null' || videoData.remoteVid === null
                  ? videoData.vid
                  : videoData.remoteVid;
              updateThumbnail(videoIdNew)
                .then(value => {
                  videoData.src = value;
                  element.outerHTML = createVideoContainer(
                    videoData,
                    element.getAttribute('status'),
                    value === null,
                    editorCx,
                    formatMessage,
                    loadingCx
                  );
                  updateVideoIndicators(videoId, editor, editorCx, formatMessage);
                  updateProcessingVideoIndicator(
                    videoData.vid,
                    editor,
                    editorCx,
                    formatMessage,
                    value !== null,
                    value
                  );
                  return value;
                })
                .catch(error => log.error(error, 'An error occurred while fetching video details'));
            } else {
              element.outerHTML = createVideoContainer(
                videoData,
                element.getAttribute('status'),
                false,
                editorCx,
                formatMessage,
                loadingCx
              );
            }
          }
        });
    });
  });
}

/**
 * Registers an attribute filter that converts the video container HTML to `li-video` XML used for storing
 * the video contents.
 *
 * @param editor the TinyMCE Editor.
 */
function registerHtmlToXmlConverter(editor: Editor): void {
  editor.serializer.addAttributeFilter('data-lia-video-container', nodes => {
    nodes.forEach(node => {
      let showCaption = 'false';
      let captionContents = '';
      let captionHtmlContents;
      const videoId = node.attr('data-lia-video-vid');
      const containerElement = getVideoContainerById(editor, videoId);
      const imgNode = getImgElement(containerElement);
      const source =
        containerElement?.getAttribute('data-lia-video-src') ?? imgNode?.getAttribute('src');

      if (containerElement) {
        const captionElement = containerElement.querySelector<HTMLDivElement>(
          `[${DATA_MEDIA_CAPTION}]`
        );
        if (captionElement) {
          // Remove TinyMce br tags
          captionElement.querySelector(`${DATA_MCE_BOGUS}`)?.remove();
          captionHtmlContents = captionElement.innerHTML.replaceAll('&nbsp;', '').trim();
          if (captionHtmlContents?.length > 0) {
            showCaption = 'true';
            captionContents = captionHtmlContents;
          }
        }
      }
      const liVideoTemplate = `<li-video>${captionContents}</li-video>`;
      const domParser = window.tinymce.html.DomParser(
        editor.editorManager.defaultOptions,
        editor.schema
      );
      const liVideoNode = domParser.parse(liVideoTemplate).firstChild;
      liVideoNode.attr('vid', node.attr('data-lia-video-vid'));
      liVideoNode.attr('remotevid', node.attr('data-lia-video-remote-vid'));
      liVideoNode.attr('src', source);
      liVideoNode.attr('caption', showCaption);
      liVideoNode.attr('size', node.attr(DATA_MEDIA_SIZE));
      liVideoNode.attr('align', node.attr(DATA_MEDIA_ALIGN));
      liVideoNode.attr('layout', node.attr(DATA_MEDIA_LAYOUT));
      liVideoNode.attr('thumbnail', node.attr(DATA_VIDEO_THUMBNAIL));
      liVideoNode.attr('title', node.attr('data-lia-video-title'));
      liVideoNode.attr('resized', node.attr(DATA_MEDIA_RESIZED));
      liVideoNode.attr('external', node.attr('data-lia-video-external'));
      liVideoNode.attr('status', node.attr(DATA_VIDEO_STATUS));
      node.replace(liVideoNode);
    });
  });
}

/**
 * Registers the context toolbar for the video selection in RTE.
 *
 * @param editor the TinyMCE Editor.
 * @param items the context toolbar items
 */
function registerContextToolbar(editor: Editor, items: string): void {
  editor.ui.registry.addContextToolbar(CustomEditorButton.VIDEO, {
    predicate: element => {
      const selectedNode = editor.selection.getNode();
      const isElementFigCaption = isFigCaption(selectedNode);
      return isVideoFigure(element) && !(isElementFigCaption || selectedNode.closest('figcaption'));
    },
    items,
    position: 'selection',
    scope: 'node'
  });
}

/**
 * Registers XML and HTML converters related to videos.
 *
 * @param editor the TinyMCE Editor.
 * @param formatMessage localizes message.
 * @param editorCx editor styles.
 * @param loadingCx local styles.
 * @param isMobile whether the current device is a mobile.
 * @param updateSourceForMediaElementCallback callback to update src if not present.
 * @param updateThumbnail callback to update thumbnail for videos.
 * @param isExternalVideoCookieBannerEnabled whether or not the config to display external video cookie banner is enabled
 */
function registerVideo(
  editor: Editor,
  formatMessage: FormatMessage,
  editorCx: ClassNamesFnWrapper,
  loadingCx: ClassNamesFnWrapper,
  isMobile: boolean,
  updateSourceForMediaElementCallback: (element: Element, videoInfo: VideoData) => void,
  updateThumbnail: (vid: string) => Promise<string>,
  isExternalVideoCookieBannerEnabled?: boolean
): void {
  const alignmentOptions = Object.values(AlignmentOptions).join(' ');

  if (!isMobile) {
    registerContextToolbar(editor, `${Object.values(MediaSize).join(' ')} | ${alignmentOptions}`);
  }
  editor.on('preInit', () => {
    registerHtmlToXmlConverter(editor);
    registerXmlToHtmlConverter(
      editor,
      formatMessage,
      editorCx,
      loadingCx,
      updateSourceForMediaElementCallback,
      updateThumbnail,
      isExternalVideoCookieBannerEnabled
    );
  });

  editor.on('click', () => {
    const selection = editor.selection.getNode();
    const isClosestFigCaption = selection.closest('figcaption');
    if (isVideoFigure(selection) && hasVideoOverlay(selection)) {
      // eslint-disable-next-line unicorn/prefer-dom-node-dataset
      selection.setAttribute('data-mce-selected', '2');
      const figCaptionElement = selection.querySelector('figcaption');
      if (isFigCaption(figCaptionElement) || isClosestFigCaption) {
        figCaptionElement.focus();
      }
    }

    /**
     * If clicked on figcaption and closest figure has video attributes, then add mce-selected
     */
    if (isFigCaption(selection) || isClosestFigCaption) {
      const closestFigure = selection.closest('figure');
      if (closestFigure.hasAttribute(DATA_VIDEO_ID)) {
        // eslint-disable-next-line unicorn/prefer-dom-node-dataset
        closestFigure.setAttribute('data-mce-selected', '2');
      }
    }
  });

  /**
   * If clicked outside and element is a figcaption,then remove mce-selected from nearest figure
   * which was the video
   */
  editor.on('focusout', () => {
    const selection = editor.selection.getNode();
    if (isFigCaption(selection) || selection.closest('figcaption')) {
      const closestFigure = selection.closest('figure');
      if (closestFigure.hasAttribute(DATA_VIDEO_ID)) {
        // eslint-disable-next-line unicorn/prefer-dom-node-dataset
        closestFigure.removeAttribute('data-mce-selected');
      }
    }
  });
}

/**
 * Remove the video container from the editor in context and throws an error toast.
 *
 * @param editor Context editor
 * @param videoId ID of the error-prone video.
 * @param toastTitle Title of the error toast.
 * @param toastMessage Message of the error toast
 * @param addToast Callback method to render the error toast.
 */
function handleVideoUploadError(
  editor: Editor,
  videoId: string,
  toastTitle: string,
  toastMessage: string,
  addToast: (toastProps: ToastProps) => void
): void {
  removeVideoContainer(editor, videoId);
  const toastProps: ToastProps = {
    toastVariant: ToastVariant.BANNER,
    alertVariant: ToastAlertVariant.DANGER,
    title: toastTitle,
    message: toastMessage,
    id: `video-${videoId}-upload-error`
  };
  addToast(toastProps);
}

export {
  isVideoFigure,
  hasVideoOverlay,
  createIframeHtml,
  updateVideoContainer,
  updateProcessingVideoIndicator,
  registerVideo,
  createVideoMarkupPromises,
  getVideoContainerById,
  getVideoPlayerPlaceholder,
  insertVideoPlaceholder,
  insertVideoFromPromises,
  updateVideoContainerForIframe,
  updateVideoIndicators,
  handleVideoUploadError,
  createThumbailPreviewWithBanner
};
