// eslint-disable-next-line max-classes-per-file
import type { Editor } from 'tinymce';
import { AutocompleterType } from './EditorAutocompleterHelper';

interface ToolbarButtonInstanceApi {
  isEnabled: () => boolean;
  setEnabled: (state: boolean) => void;
  setText: (text: string) => void;
  setIcon: (icon: string) => void;
}

// Format type definitions copied from /node_modules/tinymce/tinymce.d.ts
export type Format = ApplyFormat | RemoveFormat;
export type Formats = Record<string, Format | Format[]>;
type ApplyFormat = BlockFormat | InlineFormat | SelectorFormat;
type RemoveFormat = RemoveBlockFormat | RemoveInlineFormat | RemoveSelectorFormat;
type FormatAttrOrStyleValue = string | ((vars?: FormatVars) => string);
type FormatVars = Record<string, string>;

interface CommonFormat<T> {
  ceFalseOverride?: boolean;
  classes?: string | string[];
  collapsed?: boolean;
  exact?: boolean;
  expand?: boolean;
  links?: boolean;
  onmatch?: (node: Node, fmt: T, itemName: string) => boolean;
  onformat?: (elm: Node, fmt: T, vars?: FormatVars, node?: Node | RangeLikeObject) => void;
  remove_similar?: boolean;
}

interface CommonApplyFormat<T> extends CommonFormat<T> {
  attributes?: Record<string, FormatAttrOrStyleValue>;
  preview?: string | boolean;
  styles?: Record<string, FormatAttrOrStyleValue>;
  toggle?: boolean;
  wrapper?: boolean;
  merge_siblings?: boolean;
  merge_with_parents?: boolean;
}

interface BlockFormat extends CommonApplyFormat<BlockFormat> {
  block: string;
  block_expand?: boolean;
}

interface InlineFormat extends CommonApplyFormat<InlineFormat> {
  inline: string;
  clear_child_styles?: boolean;
}

interface SelectorFormat extends CommonApplyFormat<SelectorFormat> {
  selector: string;
  defaultBlock?: string;
  inherit?: boolean;
}

interface CommonRemoveFormat<T> extends CommonFormat<T> {
  remove?: 'none' | 'empty' | 'all';
  attributes?: string[] | Record<string, FormatAttrOrStyleValue>;
  styles?: string[] | Record<string, FormatAttrOrStyleValue>;
  split?: boolean;
  deep?: boolean;
  mixed?: boolean;
}

interface RemoveBlockFormat extends CommonRemoveFormat<RemoveBlockFormat> {
  block: string;
  list_block?: string;
}

interface RemoveInlineFormat extends CommonRemoveFormat<RemoveInlineFormat> {
  inline: string;
  preserve_attributes?: string[];
}

interface RemoveSelectorFormat extends CommonRemoveFormat<RemoveSelectorFormat> {
  selector: string;
}

interface RangeLikeObject {
  startContainer: Node;
  startOffset: number;
  endContainer: Node;
  endOffset: number;
}

export type OnAction = (api?: ToolbarButtonInstanceApi) => void;

interface CommonMenuItemSpec {
  disabled?: boolean;
  text?: string;
  value?: string;
  meta?: Record<string, never>;
  shortcut?: string;
}

export interface ChoiceMenuItemSpec extends CommonMenuItemSpec {
  type?: 'choiceitem';
  icon?: string;
}

export interface BaseToolbarToggleButtonInstanceApi extends ToolbarButtonInstanceApi {
  isActive: () => boolean;
  setActive: (state: boolean) => void;
}

export interface MenuItemSpec extends CommonMenuItemSpec {
  type?: 'menuitem';
  icon?: string;
  onSetup?: (api: ToolbarButtonInstanceApi) => (api: ToolbarButtonInstanceApi) => void;
  onAction?: (api: ToolbarButtonInstanceApi) => void;
}

const DATA_MCE_BOGUS = 'data-mce-bogus';
const DATA_MCE_AUTOCOMPLETER = 'data-mce-autocompleter';

/**
 * Partial copies of some TinyMce internal APIs needed to accomplish the desired behaviors
 * for our use cases. Current use cases include:
 *
 * <ul>
 *   <li>Forcing a root block on a contenteditable inside of a non-contenteditable</li>
 * </ul>
 *
 * @author Adam Ayres
 */

/**
 * Partial copy of TinyMce internals `NodeType`.
 *
 * https://github.com/tinymce/tinymce/blob/release/5.5/modules/tinymce/src/core/main/ts/dom/NodeType.ts
 */
// eslint-disable-next-line unicorn/no-static-only-class
class NodeType {
  static isNodeType(type: number) {
    return (node: Node) => {
      return !!node && node.nodeType === type;
    };
  }

  static isText = NodeType.isNodeType(3) as (node: Node) => node is Text;

  static isElement = NodeType.isNodeType(1) as (node: Node) => node is HTMLElement;
}

/**
 * Partial copy of TinyMce internals `Bookmarks`.
 *
 * https://github.com/tinymce/tinymce/blob/release/5.5/modules/tinymce/src/core/main/ts/bookmark/Bookmarks.ts
 */
const Bookmarks = {
  isBookmarkNode(node: Node): boolean {
    return (
      NodeType.isElement(node) &&
      node.tagName === 'SPAN' &&
      // eslint-disable-next-line unicorn/prefer-dom-node-dataset
      node.getAttribute('data-mce-type') === 'bookmark'
    );
  }
};

/**
 * Partial copy of TinyMce internals `ForceBlocks`.
 *
 * https://github.com/tinymce/tinymce/blob/release/5.5/modules/tinymce/src/core/main/ts/ForceBlocks.ts
 */
const ForceBlocks = {
  isBlockElement(blockElements, node) {
    // eslint-disable-next-line no-prototype-builtins
    return blockElements.hasOwnProperty(node.nodeName);
  },

  isValidTarget(editor: Editor, blockElements, node) {
    if (NodeType.isText(node)) {
      return true;
    } else if (NodeType.isElement(node)) {
      return !ForceBlocks.isBlockElement(blockElements, node) && !Bookmarks.isBookmarkNode(node);
    } else {
      return false;
    }
  },

  shouldRemoveTextNode(blockElements, node) {
    if (NodeType.isText(node)) {
      if (node.nodeValue.length === 0) {
        return true;
      } else if (
        /^\s+$/.test(node.nodeValue) &&
        (!node.nextSibling || ForceBlocks.isBlockElement(blockElements, node.nextSibling))
      ) {
        return true;
      }
    }

    return false;
  },

  addRootBlocksForElement(editor: Editor, parent: Element, element: Element) {
    const blockElements = editor.schema.getBlockElements();

    let node = element?.firstChild as Node;
    let tempNode;

    while (node) {
      if (ForceBlocks.shouldRemoveTextNode(blockElements, node)) {
        tempNode = node;
        node = node.nextSibling;
        editor.dom.remove(tempNode);
        // eslint-disable-next-line no-continue
        continue;
      }

      /**
       * When the spoiler is empty and the delete key is pressed, it will remove the root paragraph
       * tag. Also, when using an unordered/ordered list with no other content and then removing the list,
       * we need to add back the root paragraph tag.
       */
      if (NodeType.isElement(node) && node?.matches(`[${DATA_MCE_BOGUS}]`)) {
        const wrapper = editor.dom.create('p');
        node.before(wrapper);
        wrapper.append(node);
        editor.selection.setCursorLocation(wrapper, 0);
      }

      node = node.nextSibling;
    }
  },

  /**
   * This is a workaround of a TinyMce behavior where when the following actions are taken
   * it can result in the child element being the selected range as opposed to the root paragraph
   * element:
   *
   * - Add a spoiler
   * - immediately press delete key
   * - Add an unordered/ordered list
   *
   * On the last step when the list is added it will not recognize the paragraph element as the
   * selection range and instead considers the child element (the container of the root paragraph tag)
   * as the selection range. This causes an issue where the paragraph tag is embedded inside of the resulting
   * <li> element of the list and creates unwanted behaviors when attempting to remove the list.
   *
   *
   * @param editor the editor
   * @param childSelector the selector of the child to prevent selection
   */
  preventSelectionOnChildElement(editor: Editor, childSelector) {
    editor.on('GetSelectionRange', event => {
      const { startContainer } = event.range;
      if (
        NodeType.isElement(startContainer) &&
        startContainer.matches(childSelector) &&
        startContainer.firstChild
      ) {
        const range = document.createRange();
        range.setStart(startContainer.firstChild, 0);
        range.setEnd(startContainer.firstChild, 0);
        // eslint-disable-next-line no-param-reassign
        event.range = range;
      }
    });
  },

  setup(editor: Editor, parentSelector: string, childSelector: string) {
    editor.on('NodeChange', ({ parents }) => {
      const matchedParent = parents.find(node =>
        (node as unknown as Element)?.matches(parentSelector)
      ) as Element;
      if (matchedParent) {
        const childElement = matchedParent.querySelector(childSelector);
        ForceBlocks.addRootBlocksForElement(editor, matchedParent, childElement);
      }
    });

    ForceBlocks.preventSelectionOnChildElement(editor, childSelector);
  }
};

/**
 * Partial copy from files in:
 *
 * `tinymce/src/plugins/link`
 */
const Actions = {
  // Copied from tinymce/src/plugins/link/main/ts/core/Actions.ts
  toggleState(editor: Editor, toggler: (event) => void) {
    editor.on('NodeChange', toggler);
    return () => editor.off('NodeChange', toggler);
  }
};

// Copied from tinymce/src/plugins/paste
const PastePlugin = {
  // Copied from tinymce/src/plugins/paste/main/ts/core/Clipboard.ts
  Clipboard: {
    isKeyboardPasteEvent(event: KeyboardEvent) {
      return (event.metaKey && event.keyCode === 86) || (event.shiftKey && event.keyCode === 45);
    }
  }
};

// Copied from src/plugins/autolink/main/ts/core/Keys.ts
const scopeIndex = (container, index: number) => {
  let indexClone = index;
  if (indexClone < 0) {
    indexClone = 0;
  }

  if (container.nodeType === 3) {
    const datalength = container.data.length;

    if (indexClone > datalength) {
      indexClone = datalength;
    }
  }
  return indexClone;
};

// Copied from src/plugins/autolink/main/ts/core/Keys.ts
const setStart = (rng: Range, container: Node, offset: number) => {
  if (container.nodeType !== 1 || container.hasChildNodes()) {
    rng.setStart(container, scopeIndex(container, offset));
  } else {
    rng.setStartBefore(container);
  }
};

// Copied from src/plugins/autolink/main/ts/core/Keys.ts
const setEnd = (rng: Range, container: Node, offset: number) => {
  if (container.nodeType !== 1 || container.hasChildNodes()) {
    rng.setEnd(container, scopeIndex(container, offset));
  } else {
    rng.setEndAfter(container);
  }
};

function setEditorRange(editor: Editor, autocompleterType: AutocompleterType) {
  const triggerChar = autocompleterType === AutocompleterType.EMOJI ? ':' : '@';
  const rng = editor.selection.getRng().cloneRange();
  let { endContainer } = rng;

  if (endContainer.nodeType === 3 && endContainer.textContent.startsWith(triggerChar)) {
    setStart(rng, endContainer, 0);
    setEnd(rng, endContainer, endContainer.nodeValue.length);
  } else if (endContainer.nodeType === 3 && !endContainer.textContent.startsWith(triggerChar)) {
    return;
  } else {
    while (endContainer.nodeType !== 3 || !endContainer.textContent.startsWith(triggerChar)) {
      if (endContainer.firstChild) {
        endContainer = endContainer.firstChild;
      } else if (endContainer.nextSibling) {
        endContainer = endContainer.nextSibling;
      } else if (endContainer.parentElement?.nextSibling) {
        endContainer = endContainer.parentElement.nextSibling;
      }
    }
    setStart(rng, endContainer, 0);
    setEnd(rng, endContainer, endContainer.nodeValue.length);
  }
  editor.selection.setRng(rng, true);
}

export {
  DATA_MCE_BOGUS,
  DATA_MCE_AUTOCOMPLETER,
  NodeType,
  ForceBlocks,
  Bookmarks,
  Actions,
  PastePlugin,
  setEditorRange
};
