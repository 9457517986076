import type { AstNode, Editor } from 'tinymce';
import { buildFileName } from './EditorPasteHelper';

/**
 * Helper module to filter MS word content.
 *
 * Most of these helper functions are referenced from Tinymce paste plugin
 * aurora/node_modules/tinymce/plugins/paste/plugin.js
 *
 * @author Sravan Reddy
 */

const nbsp = '\u00A0';

/**
 * Returns true if the text is bullet list.
 *
 * @param text
 */
export function isBulletList(text: string): boolean {
  return /^\s*[\u00A7\u00B7\u2022\u25CF]\s*/.test(text);
}

/**
 * Returns true if the text is Numeric list.
 *
 * @param text
 */
export function isNumericList(text: string): boolean {
  let found = false;
  const tinymceToolsResolver = window.tinymce.util.Tools.resolve('tinymce.util.Tools');
  const patterns = [
    /^[CDILMVX]+\.[ \u00A0]/,
    /^[cdilmvx]+\.[ \u00A0]/,
    /^[a-z]{1,2}[).][ \u00A0]/,
    /^[A-Z]{1,2}[).][ \u00A0]/,
    /^\d+\.[ \u00A0]/,
    /^[\u3007\u4E00\u4E03\u4E09\u4E5D\u4E8C\u4E94\u516B\u516D\u56DB]+\.[ \u00A0]/,
    /^[\u4E03\u4E5D\u4F0D\u516B\u516D\u53C2\u56DB\u58F1\u5F10\u62FE]+\.[ \u00A0]/
  ];
  const text1 = text.replace(/^[ \u00A0]+/, '');
  tinymceToolsResolver.each(patterns, pattern => {
    if (pattern.test(text1)) {
      found = true;
      return false;
    }
  });
  return found;
}

/**
 * Valid MS word elements along with the valid attributes on those elements.
 */
const wordValidElements: string =
  '-strong/b,-em/i,-u,-span,-p,-ol,-ul,-li,-h1,-h2,-h3,-h4,-h5,-h6,' +
  '-p/div,-a[href|name],sub,sup,strike,br,del,table[width],tr,' +
  'td[colspan|rowspan|width],th[colspan|rowspan|width],thead,tfoot,tbody,img[src|height|width]';

/**
 * Filters a string of content based on the provided items.
 * @param content - The content to be filtered.
 * @param items - An array of items that will be used to filter the content.
 * Each item can be either a regular expression or an array containing a search value and a replacement value.
 * @return {string} The filtered content.
 */
export function filterContentWithItemsPassed(
  content: string,
  items: Array<[RegExp | string, RegExp | string | Function] | RegExp>
): string {
  let result = content;
  const tinymceToolsResolver = window.tinymce.util.Tools.resolve('tinymce.util.Tools');
  tinymceToolsResolver.each(items, v => {
    if (v instanceof RegExp) {
      result = result.replace(v, '');
    } else {
      result = result.replace(v[0], v[1]);
    }
  });
  return result;
}

/**
 * Removes the nodes from the content.
 *
 * @param nodes
 */
export function deleteNodeFilter(nodes: AstNode[]): void {
  let i = nodes.length - 1;
  while (i >= 0) {
    nodes[i].remove();
    i = i - 1;
  }
}

/**
 * Filters and transforms the anchor nodes from the content.
 * @param nodes
 */
export function anchorNodeFilter(nodes) {
  let i = nodes.length,
    node,
    href,
    name;
  while (i) {
    i = i - 1;
    node = nodes[i];
    href = node.attr('href');
    name = node.attr('name');
    if (href && href.includes('#_msocom_')) {
      node.remove();
      continue;
    }
    if (href && href.indexOf('file://') === 0) {
      href = href.split('#').pop();
      if (href) {
        href = '#' + href;
      }
    }
    if (!href && !name) {
      node.unwrap();
    } else {
      if (name && !/^_?(?:toc|edn|ftn)/i.test(name)) {
        node.unwrap();
        continue;
      }
      node.attr({
        href: href,
        name: name
      });
    }
  }
}

/**
 * Filters and transforms the classes on nodes.
 *
 * @param nodes
 */
export function classNodeFilter(nodes): void {
  let i = nodes.length,
    node,
    className;
  while (i) {
    i = i - 1;
    node = nodes[i];
    className = node.attr('class');
    if (/^(msocommentreference|msocommenttext|msodel)$/i.test(className)) {
      node.remove();
    }
    node.attr('class', null);
  }
}

/**
 * Transforms/Trims the start of List elements.
 *
 * @param currentNode
 * @param regExp
 */
export function trimListStart(currentNode: AstNode, regExp: RegExp): boolean {
  let node = currentNode;
  if (node.type === 3 && regExp.test(node.value)) {
    node.value = node.value.replace(regExp, '');
    return false;
  }
  if ((node = node.firstChild)) {
    do {
      if (!trimListStart(node, regExp)) {
        return false;
      }
    } while ((node = node.next));
  }
  return true;
}

/**
 * Returns the text of the given node.
 *
 * @param currentNode
 */
export function getText(currentNode: AstNode): string {
  let txt = '',
    node = currentNode;
  if (node.type === 3) {
    return node.value;
  }
  if ((node = node.firstChild)) {
    do {
      txt += getText(node);
    } while ((node = node.next));
  }
  return txt;
}

/**
 * Removes the ignored nodes from the given node.
 *
 * @param node
 */
export function removeIgnoredNodes(node): void {
  if (node._listIgnore) {
    node.remove();
    return;
  }
  let currentNode = node;
  if ((currentNode = currentNode.firstChild)) {
    do {
      removeIgnoredNodes(currentNode);
    } while ((currentNode = currentNode.next));
  }
}

/**
 * Converts the fake lists to proper Html Lists.
 *
 * @param currentNode
 */
export function convertFakeListsToProperLists(currentNode): void {
  let node = currentNode;
  const tinymceNodeResolver = window.tinymce.util.Tools.resolve('tinymce.html.Node');
  let currentListNode,
    previousListNode,
    lastLevel = 1;
  function convertParagraphToLi(paragraphNode, listName, start): void {
    const level = paragraphNode._listLevel || lastLevel;
    if (level !== lastLevel) {
      if (level < lastLevel) {
        if (currentListNode) {
          currentListNode = currentListNode.parent.parent;
        }
      } else {
        previousListNode = currentListNode;
        currentListNode = null;
      }
    }
    if (!currentListNode || currentListNode.name !== listName) {
      previousListNode = previousListNode || currentListNode;
      currentListNode = new tinymceNodeResolver(listName, 1);
      if (start > 1) {
        currentListNode.attr('start', '' + start);
      }
      paragraphNode.wrap(currentListNode);
    } else {
      currentListNode.append(paragraphNode);
    }
    paragraphNode.name = 'li';
    if (level > lastLevel && previousListNode) {
      previousListNode.lastChild.append(currentListNode);
    }
    lastLevel = level;
    removeIgnoredNodes(paragraphNode);
    trimListStart(paragraphNode, /^\u00A0+/);
    trimListStart(paragraphNode, /^\s*([\u00A7\u00B7\u2022\u25CF]|\w+\.)/);
    trimListStart(paragraphNode, /^\u00A0+/);
  }
  const elements = [];
  let child = node.firstChild;
  while (child !== undefined && child !== null) {
    elements.push(child);
    child = child.walk();
    if (child !== null) {
      while (child !== undefined && child.parent !== node) {
        child = child.walk();
      }
    }
  }
  for (const element of elements) {
    node = element;
    if (node.name === 'p' && node.firstChild) {
      const nodeText = getText(node);
      if (isBulletList(nodeText)) {
        convertParagraphToLi(node, 'ul', 0);
        continue;
      }
      if (isNumericList(nodeText)) {
        const matches = /(\d+)\./.exec(nodeText);
        let start = 1;
        if (matches) {
          start = Number.parseInt(matches[1], 10);
        }
        convertParagraphToLi(node, 'ol', start);
        continue;
      }
      if (node._listLevel) {
        convertParagraphToLi(node, 'ul', 1);
        continue;
      }
      currentListNode = null;
    } else {
      previousListNode = currentListNode;
      currentListNode = null;
    }
  }
}

/**
 * An Attribute filter to transform style attributes on nodes.
 *
 * @param nodes
 */
export function styleAttrFilter(nodes): void {
  let i = nodes.length,
    node;
  while (i) {
    i = i - 1;
    node = nodes[i];
    node.attr('style', null);
    if (node.name === 'span' && node.parent && node.attributes.length === 0) {
      node.unwrap();
    }
  }
}

/**
 * Filters the word content - converts word - meta tags to html
 * Removes inline styling that comes from word.
 *
 * @param editor
 * @param content
 */
function filterWordContent(editor: Editor, content: string): string {
  const tinymceSchemaResolver = window.tinymce.util.Tools.resolve('tinymce.html.Schema');
  const tinymceSerializer = window.tinymce.util.Tools.resolve('tinymce.html.Serializer');
  const tinymceDomParser = window.tinymce.util.Tools.resolve('tinymce.html.DomParser');
  const tinymceToolsResolver = window.tinymce.util.Tools.resolve('tinymce.util.Tools');
  let result;
  result = filterContentWithItemsPassed(content, [
    /<br class="?apple-interchange-newline"?>/gi,
    /<b[^>]+id="?docs-internal-[^>]*>/gi,
    /<!--[\S\s]+?-->/gi,
    /<(!|script[^>]*>.*?<\/script(?=[\s>])|\/?(\?xml(:\w+)?|meta|link|style|\w:\w+)(?=[\s/>]))[^>]*>/gi,
    [/<(\/?)s>/gi, '<$1strike>'],
    [/&nbsp;/gi, nbsp],
    [
      /<span\s+style\s*=\s*"\s*mso-spacerun\s*:\s*yes\s*;?\s*"\s*>(\s*)<\/span>/gi,
      (string_, spaces) => {
        return spaces.length > 0
          ? spaces
              .replace(/./, ' ')
              .slice(Math.floor(spaces.length / 2))
              .join(nbsp)
          : '';
      }
    ]
  ]);
  const schema = tinymceSchemaResolver({
    valid_elements: wordValidElements,
    valid_children: '-li[p]'
  });

  tinymceToolsResolver.each(schema.elements, rule => {
    if (!rule.attributes.class) {
      rule.attributes.class = {};
      rule.attributesOrder.push('class');
    }
    if (!rule.attributes.style) {
      rule.attributes.style = {};
      rule.attributesOrder.push('style');
    }
  });

  const domParser = tinymceDomParser({}, schema);
  domParser.addAttributeFilter('style', styleAttrFilter);
  domParser.addAttributeFilter('class', classNodeFilter);
  domParser.addNodeFilter('del', deleteNodeFilter);
  domParser.addNodeFilter('a', anchorNodeFilter);
  domParser.addNodeFilter('a', anchorNodeFilter);
  const rootNode = domParser.parse(result);
  convertFakeListsToProperLists(rootNode);
  result = tinymceSerializer({ validate: editor.getParam('validate') }, schema).serialize(rootNode);
  return result;
}

/**
 * Returns the file name excluding date part and extension.
 * which can be used in identifying the placeholder divs with
 * data attribute data-lia-image-container
 *
 * @param name
 */
export function getPlaceholderAttributeValue(name: string): string {
  return name.slice(0, Math.max(0, name.lastIndexOf('-')));
}

/**
 * Replaces img tags in the content with placeholder divs.
 *
 * @param html the html content in which tags needs to be replaced.
 * @param baseIndex to maintain sync between pre process and post process handlers.
 */
function replaceTagsFromHtml(html: string, baseIndex: number) {
  let index = baseIndex;
  function convert(): string {
    index = index + 1;
    const containerAttribute = getPlaceholderAttributeValue(buildFileName(index, ''));
    return `<div id="copypaste_${index}" class="mceNonEditable" data-lia-image-container=${containerAttribute} data-lia-copypaste-image></div`;
  }

  const regexp = /<img[^>]+/g;
  return { html: html.replaceAll(regexp, convert), updatedBaseIndex: index };
}

export { filterWordContent, replaceTagsFromHtml };
