import type { Editor } from 'tinymce';

export type OnResolve = (pattern: string, autocompleter: AutocompleterType) => void;

export enum AutocompleterType {
  EMOJI = 'emoji',
  MENTIONS = 'mentions'
}

export interface AutocompleterProps {
  /**
   * Sets the active autocompleter state.
   *
   * @param autocompleter - the autocompleter type.
   */
  onSetActiveAutocompleter: (autocompleter: AutocompleterType) => void;
  /**
   * The search term to filter the results.
   */
  searchTerm: string;
  /**
   * Instance of the active tinymce editor
   */
  editor: Editor;
}

/**
 * Register an autocompleter using the autocompleter plugin and API provided by TinyMCE.
 *
 * @param editor
 * @param onResolve
 * @param name
 * @param triggerChar
 */
function registerAutocompleter(
  editor: Editor,
  onResolve: OnResolve,
  name: AutocompleterType,
  triggerChar: string
) {
  editor.ui.registry.addAutocompleter(name, {
    ch: triggerChar,
    onAction: () => null,
    fetch: pattern => {
      return new Promise(() => {
        setTimeout(() => {
          onResolve(pattern, name);
        });
      });
    }
  });
}

/**
 * Registers one or more autocompleters for the RTE.
 *
 * @param editor
 * @param onResolve
 */
export default function registerEditorAutocompleter(editor: Editor, onResolve: OnResolve) {
  registerAutocompleter(editor, onResolve, AutocompleterType.EMOJI, ':');
  registerAutocompleter(editor, onResolve, AutocompleterType.MENTIONS, '@');
}
