import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import isEqual from 'react-fast-compare';
import type { AstNode, Editor, RawEditorOptions } from 'tinymce';
import { canUseDOM } from 'exenv';
import type { FormatMessage } from '@aurora/shared-types/texts';
import { prefixStaticAsset } from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import type { UsePopperOptions } from 'react-overlays/usePopper';
import type { MessageEditorFormData } from '../../components/messages';
import type { MessageEditorFormField } from '../../types/enums';
import { forecolorFormat } from './EditorForecolorHelper';
import type { Format, Formats } from './TinyMceInternalHelper';
// eslint-disable-next-line import/no-cycle
import { getImageAlignFormat } from './EditorMediaHelper';
import versions from '../../generated/static-hashes.json';

/**
 * Custom TinyMce Buttons
 */
export enum CustomEditorButton {
  CODE_SAMPLE = 'liaCodeSample',
  HELP = 'liaHelp',
  LINK = 'liaLink',
  MESSAGE_ATTACHMENT = 'liaMessageAttachment',
  SPOILER = 'liaSpoiler',
  SOURCE_CODE = 'liaSourceCode',
  EMOJI = 'liaEmoji',
  MEDIA = 'liaMedia',
  ACCESSIBILITY = 'liaAccessibility',
  VIDEO = 'liaVideo',
  IMAGE_LINK = 'liaImageLink',
  ANCHOR = 'liaAnchorLink',
  TOC = 'liaToc',
  RICH_PREVIEW = 'liaRichPreview'
}

/**
 * Core TinyMce Buttons
 */
export enum CoreEditorButton {
  ALIGN = 'align',
  BOLD = 'bold',
  BLOCKQUOTE = 'blockquote',
  BULL_LIST = 'bullist',
  FORECOLOR = 'forecolor',
  FORMAT_SELECT = 'blocks',
  ITALIC = 'italic',
  INDENT = 'indent',
  NUM_LIST = 'numlist',
  OUTDENT = 'outdent',
  REMOVE_FORMAT = 'removeformat',
  SEPARATOR = '|',
  STRIKETHROUGH = 'strikethrough',
  TABLE = 'table',
  UNDERLINE = 'underline'
}

/**
 * Alignment options for media elements
 */
export enum AlignmentOptions {
  ALIGN_LEFT = 'liaAlignLeft',
  ALIGN_CENTER = 'liaAlignCenter',
  ALIGN_RIGHT = 'liaAlignRight',
  ALIGN_NONE = 'liaAlignNone'
}

/**
 * Core TinyMce Plugins. If plugins are added or removed here they also
 * need to be added or removed in TinyMceEditor.tsx.
 */
enum EditorPlugin {
  LISTS = 'lists',
  TABLE = 'table'
}

/**
 * Editor content alignments
 */
export enum ContentAlign {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
  JUSTIFY = 'justify',
  NONE = 'no-align'
}

export interface EditorModalProps {
  /**
   * Whether the modal is currently being displayed
   */
  show: boolean;
  /**
   * Callback function when the modal is hidden
   *
   * @callback
   * @param boolean - The state of the modal.
   */
  onHide: () => void;
  /**
   * Instance of the current active tinymce editor
   */
  editor: Editor;
}

/**
 * This is a copy of the type that the react-tinymce component uses. It's a union type
 * that errors when attempting to just use `RawEditorOptions`.
 */
export interface ReactTinymceEditor extends RawEditorOptions {
  /**
   * This option enables you to specify a CSS selector expression
   * that will be used to find textareas you want to convert.
   */
  selector?: undefined;
  /**
   * Sometimes there might be already a reference to a DOM element at hand,
   * for example when element is created dynamically. In such case initialising
   * editor on it by selector might seem irrational (since selector - id or class should be created first).
   * In such cases you can supply that element directly via target option.
   */
  target?: undefined;
}

export interface ToolbarGroup {
  /**
   * Title for the toolbar group
   */
  name?: string;
  /**
   * List of items to be categorized in a toolbar group
   */
  items: string[];
}

const buttonToPluginMap: Partial<Record<CoreEditorButton | CustomEditorButton, EditorPlugin>> = {
  [CoreEditorButton.BULL_LIST]: EditorPlugin.LISTS,
  [CoreEditorButton.NUM_LIST]: EditorPlugin.LISTS,
  [CoreEditorButton.TABLE]: EditorPlugin.TABLE
};

function collectPluginsForButtons(buttons: Array<ToolbarGroup>) {
  const plugins = [];
  buttons.forEach(button => {
    button.items.forEach(item => {
      const plugin = buttonToPluginMap[item];
      if (plugin && !plugins.includes(plugin)) {
        plugins.push(plugin);
      }
    });
  });
  return plugins;
}

/**
 * Get the alignment format configurations for the editor's content
 *
 * @param align Required alignment
 */
function getAlignFormat(align: string): Format | Format[] {
  return { selector: ':not(span, figure, a, sup, figcaption)', classes: `lia-align-${align}` };
}

/**
 * Get the custom format configurations for the editor's content
 */
function getCustomContentFormat(): Formats {
  return {
    strikethrough: { inline: 'del' },
    underline: { inline: 'u' },
    alignright: getAlignFormat(ContentAlign.RIGHT),
    alignleft: getAlignFormat(ContentAlign.LEFT),
    aligncenter: getAlignFormat(ContentAlign.CENTER),
    alignjustify: getAlignFormat(ContentAlign.JUSTIFY),
    mediaalignright: getImageAlignFormat(ContentAlign.RIGHT),
    mediaalignleft: getImageAlignFormat(ContentAlign.LEFT),
    mediaaligncenter: getImageAlignFormat(ContentAlign.CENTER),
    mediaalignnone: getImageAlignFormat(ContentAlign.NONE),
    forecolor: forecolorFormat
  };
}

/**
 * Returns all the available markdown syntax for RTE
 */
function getMarkdownTextPatterns() {
  return [
    { start: '_', end: '_', format: CoreEditorButton.ITALIC },
    { start: '*', end: '*', format: CoreEditorButton.ITALIC },
    { start: '__', end: '__', format: CoreEditorButton.BOLD },
    { start: '**', end: '**', format: CoreEditorButton.BOLD },
    { start: '___', end: '___', format: `${CoreEditorButton.BOLD} ${CoreEditorButton.ITALIC}` },
    { start: '***', end: '***', format: `${CoreEditorButton.BOLD} ${CoreEditorButton.ITALIC}` },
    { start: '~', end: '~', format: CoreEditorButton.STRIKETHROUGH },
    { start: '# ', format: 'h1' },
    { start: '## ', format: 'h2' },
    { start: '### ', format: 'h3' },
    { start: '#### ', format: 'h4' },
    { start: '##### ', format: 'h5' },
    { start: '###### ', format: 'h6' },
    { start: '* ', cmd: 'InsertUnorderedList' },
    { start: '- ', cmd: 'InsertUnorderedList' },
    { start: '1. ', cmd: 'InsertOrderedList' },
    { start: '1) ', cmd: 'InsertOrderedList' },
    { start: '> ', format: CoreEditorButton.BLOCKQUOTE },
    { start: '```', cmd: CustomEditorButton.CODE_SAMPLE }
  ];
}

/**
 * Editor settings used as a base for all instances of the editor.
 *
 */
function editorSettingsBase(): RawEditorOptions {
  return {
    branding: false,
    statusbar: false,
    menubar: false,
    toolbar_mode: 'floating',
    skin: 'oxide',
    skin_url: prefixStaticAsset(`/rte/skins/${versions.rte}/ui/oxide`),
    icons: 'khoros',
    icons_url: prefixStaticAsset(`/static/tinymce/${versions.tinymce}/khoros/icons.js`),
    inline: true,
    custom_colors: false,
    end_container_on_empty_block: true,
    /**
     * This should be replaced with the allowed elements and attributes as specified in the
     * LIA backend.
     */
    invalid_elements: 'style',
    // Custom tags should be supported in LIA backend and its attributes should be added in CustomMacrosEnum
    extended_valid_elements:
      'li-spoiler[id|class|contenteditable|usefocus|label],' +
      'li-anchor[id|class|as],' +
      'li-code[lang],' +
      'li-rich-preview[src|title|description|thumbnail|favicon|provider],' +
      'li-image[src|loading|id|caption|width|height|alt|align|resized|size|sourcetype|assettoken|srcset|*],' +
      'li-video[src|vid|width|height|external|thumbnail|title|align|size|thumbnailwidth|thumbnailheight|alt|layout|resized|caption|status|remotevid],' +
      'li-user[login|uid],' +
      'li-message[uid|title|content-type|title-override|topic-id|topic-subject],' +
      'li-node[display-id|title|node-type|content-type|parent-id|title-override],' +
      'li-frame[src|title|scrolling|allowfullscreen|allow|frameborder|class|style|wrapper-style],' +
      'li-gist[src],' +
      'svg[*],' +
      'path[*],' +
      'iframe[src|width|height|title|scrolling|allowfullscreen|allow|frameborder|class|style|sandbox],' +
      'span[class]' +
      'link[rel|href]',
    custom_elements:
      'li-spoiler,li-code,li-image,li-wrapper,~li-user,~li-message,~li-node,li-video,li-rich-preview,li-anchor,li-frame,li-gist',
    valid_children:
      '+li-spoiler[p|a|#text],+li-code[#text],+li-image[#text],+li-user[#text],+p[li-video|li-user|li-message|li-node|li-rich-preview|li-anchor],+a[img|li-image|li-anchor],+div[link],+body[link],+li-anchor[#text],+h1[li-anchor],+h2[li-anchor],+h3[li-anchor],+h4[li-anchor],+h5[li-anchor],+h6[li-anchor]',
    formats: getCustomContentFormat(),
    liaMaxIndentation: 300,
    liaIndentationStep: 30,
    external_plugins: {},
    browser_spellcheck: true,
    contextmenu: false,
    link_assume_external_targets: 'http',
    allow_svg_data_urls: true,
    allow_html_data_urls: true,
    relative_urls: false,
    convert_urls: false,
    // To disable the TinyMCE 6.0 deprecation warning
    deprecation_warnings: false,
    text_patterns: getMarkdownTextPatterns(),
    visual_anchor_class: 'lia-anchor'
  };
}

/**
 * Editor settings used for "inline" instances of the editor.
 *
 */
export function editorSettingsForInline(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.list.blockquote',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST, CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media.emoji',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA, CustomEditorButton.EMOJI]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.attachment.code.help',
      items: [
        CustomEditorButton.MESSAGE_ATTACHMENT,
        CustomEditorButton.CODE_SAMPLE,
        CustomEditorButton.HELP
      ]
    }
  ];

  const mobileToolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.list.blockquote',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST, CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.media',
      items: [CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.attachment.code',
      items: [CustomEditorButton.MESSAGE_ATTACHMENT, CustomEditorButton.CODE_SAMPLE]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    }
  };
}

/**
 * Editor settings used for "partial" instances of the editor.
 *
 */
export function editorSettingsForPartial(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    }
  ];

  const plugins = collectPluginsForButtons(toolbar);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar
    }
  };
}

/**
 * Editor settings used for "full" instances of the editor.
 *
 */
export function editorSettingsForFull(): RawEditorOptions {
  const toolbar: Array<ToolbarGroup> = [
    {
      name: 'toolbar.group.text.format',
      items: [CoreEditorButton.FORMAT_SELECT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.text.styles',
      items: [
        CoreEditorButton.BOLD,
        CoreEditorButton.ITALIC,
        CoreEditorButton.UNDERLINE,
        CoreEditorButton.STRIKETHROUGH,
        CoreEditorButton.FORECOLOR
      ]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.alignment',
      items: [CoreEditorButton.ALIGN]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media.emoji',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA, CustomEditorButton.EMOJI]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.table',
      items: [CoreEditorButton.TABLE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.code.spoiler.anchor',
      items: [CustomEditorButton.CODE_SAMPLE, CustomEditorButton.SPOILER, CustomEditorButton.ANCHOR]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.indentation',
      items: [CoreEditorButton.INDENT, CoreEditorButton.OUTDENT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.toc.formatting.code.help',
      items: [
        CustomEditorButton.TOC,
        CoreEditorButton.REMOVE_FORMAT,
        CustomEditorButton.SOURCE_CODE,
        CustomEditorButton.HELP
      ]
    }
  ];

  const mobileToolbar: Array<ToolbarGroup> = [
    {
      name: 'toolbar.group.text.format',
      items: [CoreEditorButton.FORMAT_SELECT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.text.styles',
      items: [
        CoreEditorButton.BOLD,
        CoreEditorButton.ITALIC,
        CoreEditorButton.UNDERLINE,
        CoreEditorButton.STRIKETHROUGH,
        CoreEditorButton.FORECOLOR
      ]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.alignment',
      items: [CoreEditorButton.ALIGN]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.code.anchor.indentation',
      items: [
        CustomEditorButton.CODE_SAMPLE,
        CustomEditorButton.ANCHOR,
        CoreEditorButton.INDENT,
        CoreEditorButton.OUTDENT
      ]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.toc.formatting',
      items: [CustomEditorButton.TOC, CoreEditorButton.REMOVE_FORMAT]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    }
  };
}

/**
 * Editor settings used for "Custom Content" instances of the editor.
 *
 */
export function editorSettingsForCustomContent(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.format',
      items: [CoreEditorButton.FORMAT_SELECT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.text.styles',
      items: [
        CoreEditorButton.BOLD,
        CoreEditorButton.ITALIC,
        CoreEditorButton.UNDERLINE,
        CoreEditorButton.STRIKETHROUGH,
        CoreEditorButton.FORECOLOR
      ]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.alignment',
      items: [CoreEditorButton.ALIGN]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media.emoji',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA, CustomEditorButton.EMOJI]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.table',
      items: [CoreEditorButton.TABLE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.indentation',
      items: [CoreEditorButton.INDENT, CoreEditorButton.OUTDENT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.toc.formatting.code.help',
      items: [
        CustomEditorButton.TOC,
        CoreEditorButton.REMOVE_FORMAT,
        CustomEditorButton.SOURCE_CODE,
        CustomEditorButton.HELP
      ]
    }
  ];

  const mobileToolbar = [
    {
      name: 'toolbar.group.text.format',
      items: [CoreEditorButton.FORMAT_SELECT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.text.styles',
      items: [
        CoreEditorButton.BOLD,
        CoreEditorButton.ITALIC,
        CoreEditorButton.UNDERLINE,
        CoreEditorButton.STRIKETHROUGH,
        CoreEditorButton.FORECOLOR
      ]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.alignment',
      items: [CoreEditorButton.ALIGN]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.code.indentation',
      items: [CustomEditorButton.CODE_SAMPLE, CoreEditorButton.INDENT, CoreEditorButton.OUTDENT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.toc.formatting',
      items: [CustomEditorButton.TOC, CoreEditorButton.REMOVE_FORMAT]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    }
  };
}

/**
 * Editor settings used for private conversation.
 *
 */
export function editorSettingsForPrivateConversation(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media.emoji',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA, CustomEditorButton.EMOJI]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.help',
      items: [CustomEditorButton.HELP]
    }
  ];

  const mobileToolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    }
  };
}

/**
 * Editor settings used for terms of service.
 *
 */
export function editorSettingsForTermsOfService(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.format',
      items: [CoreEditorButton.FORMAT_SELECT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.text.styles',
      items: [
        CoreEditorButton.BOLD,
        CoreEditorButton.ITALIC,
        CoreEditorButton.UNDERLINE,
        CoreEditorButton.STRIKETHROUGH,
        CoreEditorButton.FORECOLOR
      ]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.table',
      items: [CoreEditorButton.TABLE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.alignment',
      items: [CoreEditorButton.ALIGN]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link',
      items: [CustomEditorButton.LINK]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.indentation',
      items: [CoreEditorButton.INDENT, CoreEditorButton.OUTDENT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.toc.formatting.help',
      items: [CustomEditorButton.TOC, CoreEditorButton.REMOVE_FORMAT, CustomEditorButton.HELP]
    }
  ];

  const mobileToolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    }
  };
}

/**
 * Editor settings used for private note.
 *
 */
export function editorSettingsForPrivateNote(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.list.blockquote',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST, CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media.emoji',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA, CustomEditorButton.EMOJI]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.attachment.help',
      items: [CustomEditorButton.MESSAGE_ATTACHMENT, CustomEditorButton.HELP]
    }
  ];

  const mobileToolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.list.blockquote',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST, CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.attachment',
      items: [CustomEditorButton.MESSAGE_ATTACHMENT]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    }
  };
}

/**
 * Editor settings used for update status.
 *
 */
export function editorSettingsForUpdateStatus(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link',
      items: [CustomEditorButton.LINK]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.emoji.blockquote',
      items: [CustomEditorButton.EMOJI, CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.help',
      items: [CustomEditorButton.HELP]
    }
  ];

  const mobileToolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link',
      items: [CustomEditorButton.LINK]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.emoji.blockquote',
      items: [CustomEditorButton.EMOJI, CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.help',
      items: [CustomEditorButton.HELP]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    }
  };
}

/**
 * Editor settings used for case creation
 *
 */
export function editorSettingsForCaseCreation(): RawEditorOptions {
  const toolbar = [
    {
      name: 'toolbar.group.text.format',
      items: [CoreEditorButton.FORMAT_SELECT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.text.styles',
      items: [
        CoreEditorButton.BOLD,
        CoreEditorButton.ITALIC,
        CoreEditorButton.UNDERLINE,
        CoreEditorButton.STRIKETHROUGH,
        CoreEditorButton.FORECOLOR
      ]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.table',
      items: [CoreEditorButton.TABLE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.alignment',
      items: [CoreEditorButton.ALIGN]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.indentation',
      items: [CoreEditorButton.INDENT, CoreEditorButton.OUTDENT]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.toc.formatting.code.help',
      items: [
        CustomEditorButton.TOC,
        CoreEditorButton.REMOVE_FORMAT,
        CustomEditorButton.SOURCE_CODE,
        CustomEditorButton.HELP
      ]
    }
  ];

  const mobileToolbar = [
    {
      name: 'toolbar.group.text.styles',
      items: [CoreEditorButton.BOLD, CoreEditorButton.ITALIC]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.lists',
      items: [CoreEditorButton.BULL_LIST, CoreEditorButton.NUM_LIST]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.link.media',
      items: [CustomEditorButton.LINK, CustomEditorButton.MEDIA]
    },
    {
      items: [CoreEditorButton.SEPARATOR]
    },
    {
      name: 'toolbar.group.insert.blockquote',
      items: [CoreEditorButton.BLOCKQUOTE]
    }
  ];

  const plugins = collectPluginsForButtons([...toolbar, ...mobileToolbar]);

  return {
    ...editorSettingsBase(),
    plugins,
    toolbar,
    mobile: {
      toolbar: mobileToolbar
    },
    object_resizing: false
  };
}

/**
 * Get the active editor.
 */
export function getActiveEditor(): Editor | undefined {
  return canUseDOM && window.tinymce?.activeEditor;
}

/**
 * Focus the editor on next tick. Useful when performing actions that need
 * to occur after a React rerender.
 *
 * @param editor the editor
 */
export function focusEditorNextTick(editor: Editor): void {
  setTimeout(() => {
    editor.focus();
  }, 500);
}

/**
 * Whether the focused element is a figcaption.
 *
 * @param node
 * @param editor
 */
export function isFigCaption(node: Element, editor: Editor): boolean {
  return editor.dom.getContentEditableParent(node) !== 'false' && node.nodeName === 'FIGCAPTION';
}

/**
 * Whether the selection is a figcaption
 *
 * @param node
 * @param editor
 */
export function isCaptionSelected(node: Element, editor: Editor): boolean {
  return !editor.selection.isCollapsed() && isFigCaption(node, editor);
}

/**
 * Checks whether the selected item is within the view port.
 *
 * @param selectedItem the selected item.
 * @param container overlay container.
 * @param scrollDown whether the scroll direction is down.
 */
function isItemWithinOverlay(
  selectedItem: HTMLElement,
  container: HTMLElement,
  scrollDown: boolean
): boolean {
  const selectedItemRectangle = selectedItem.getBoundingClientRect();
  const containerRefRectangle = container.getBoundingClientRect();
  return scrollDown
    ? containerRefRectangle.y + containerRefRectangle.height >
        selectedItemRectangle.y + selectedItemRectangle.height
    : containerRefRectangle.y < selectedItemRectangle.y;
}

/**
 * Scrolls the overlay to accomodate the new selected item when it is outside the view port.
 *
 * @param currentItem current selected item.
 * @param newItem newly selected item.
 * @param container overlay container.
 * @param onSetPreventMouseOver callback to prevent mouse over on scroll
 */
export function scrollIfNeeded(
  currentItem: HTMLElement,
  newItem: HTMLElement,
  container: HTMLElement,
  onSetPreventMouseOver?: () => void
): void {
  const scrollDown = newItem.offsetTop > currentItem.offsetTop;
  if (!isItemWithinOverlay(newItem, container, scrollDown)) {
    if (scrollDown) {
      container.scrollTop += newItem.offsetTop - currentItem.offsetTop;
    } else {
      container.scrollTop -= currentItem.offsetTop - newItem.offsetTop;
    }
    if (onSetPreventMouseOver) {
      onSetPreventMouseOver();
    }
  }
}

/**
 * Helper that defines the custom overlay popper config.
 *
 * @param boundaryElement the boundary element which should contain the overlay.
 */
export function getOverlayPopperConfig(boundaryElement?: HTMLElement | Document): UsePopperOptions {
  return {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          boundary: boundaryElement
        }
      },
      {
        name: 'flip',
        enabled: true,
        options: {
          boundary: boundaryElement,
          fallbackPlacements: ['top-start', 'bottom-start'],
          allowedAutoPlacements: ['top-start', 'bottom-start']
        }
      }
    ]
  };
}

/**
 * Sets the cursor location at the given element of the editor.
 */
function setCursorAtEndOfNode(activeEditor: Editor, node: Element): void {
  activeEditor?.selection.select(node, true);
  activeEditor?.selection.collapse(false);
}

/**
 * Inserts the content passed at the end of the active editor condition to the blank quote or quote with content.
 */
function insertContentAtEndOfEditor(activeEditor: Editor, htmlContent: string): void {
  setCursorAtEndOfNode(activeEditor, activeEditor.getBody());
  const { parentElement } = activeEditor?.selection.getNode();
  // If an existing quote block exists AND is empty, replace the empty quote block with the quoted reply.
  if (parentElement?.nodeName === 'BLOCKQUOTE' && parentElement?.textContent.trim().length === 0) {
    parentElement?.remove();
  }
  setCursorAtEndOfNode(activeEditor, activeEditor.getBody());
  // If the focused element at the end of the editor is empty, insertContent will  replace the empty element with the quoted reply.
  activeEditor?.insertContent(htmlContent);
}

/**
 * Fix focus issues when using inline toolbar.
 *
 * @param editor the TinyMce editor.
 * @param formatMessage localizes messages
 * @param isMac if the device is a mac
 */
export function registerInlineToolbarFixes(
  editor: Editor,
  formatMessage: FormatMessage,
  placeholder: string,
  isMac: boolean
) {
  /**
   * Force the editor toolbar to appear by manually triggering a focus event.
   * This is useful when using inline mode, otherwise the toolbar will not appear until
   * the editor is focused.
   */
  editor.on('init', () => {
    editor.dispatch('focus');
  });

  /**
   * Bring back the focus to editor when a modal toolbar button is clicked.
   */
  editor.on('OpenWindow', () => {
    editor.dispatch('focus');
  });

  editor.on('init', () => {
    /**
     * Adds an aria-label that includes help info for the editor shortcuts. There is currently
     * no direct way to apply this attribute to the element that react-tinymce creates when
     * using `inline` mode.
     */
    editor.getBody().setAttribute(
      'aria-label',
      formatMessage(`helpInfo${isMac ? 'Mac' : ''}`, {
        placeholder
      })
    );
  });
}

/**
 * Gets the element (footer) if it is blocking the cursor inside editor.
 *
 * @param cursorPosition the cursor position in the editor.
 * @param stickyFooter the blocking element to be checked for.
 */
function getElementOverlappingCursor(
  cursorPosition: DOMRect,
  stickyFooter: HTMLDivElement
): HTMLDivElement {
  const blockingElementRect = stickyFooter?.getBoundingClientRect();

  if (cursorPosition?.y + cursorPosition?.height > blockingElementRect?.y) {
    return stickyFooter;
  }
  return null;
}

/**
 * Handler to adjust the scroll if cursor goes behind the passed blocking element.
 * @param editor the editor.
 * @param stickyFooter the blocking element.
 */
function handleKeypress(editor, stickyFooter: HTMLDivElement): void {
  // create a bookmark so we can get the element position
  const bookmark = editor.selection?.getBookmark();
  // get the position of the bookmark element that was created
  const cursorPosition = document.querySelector(`#${bookmark?.id}_start`)?.getBoundingClientRect();
  // delete the bookmark by moving to it, this should be a no-op
  editor.selection?.moveToBookmark(bookmark);

  if (cursorPosition) {
    const blockingElement = getElementOverlappingCursor(cursorPosition, stickyFooter);
    // Check if there is an element at the cursor position,
    // and if it's not contained by the editor
    if (blockingElement) {
      // Get the blocking element's coordinates
      const blockingElementCoordinates = blockingElement.getBoundingClientRect();
      // Calculate how much overlap there is
      const overlapDelta = cursorPosition.y + cursorPosition.height - blockingElementCoordinates.y;
      // Scroll the page down the overlap amount, we add an additional
      // gap so the cursor is not right at the bottom but has a little space below
      // it ant the fixed toolbar
      window.scrollTo(window.scrollX, window.scrollY + overlapDelta + 10);
    }
  }
}

/**
 * Adjust cursor position when goes behind any sticky elements on the input form where RTE is placed.
 *
 * @param editor the TinyMce editor.
 * @param stickyFooter the blocking element.
 */
export function registerEditorCursorBehindFooterHandler(
  editor: Editor,
  stickyFooter: HTMLDivElement
) {
  let hasScrolled = true;
  let ticking = false;
  let editorBodyElement = null;
  let editorBodyHeight = 0;

  function isHeightOfContentChangedAndUpdate(): boolean {
    const newHeight = editorBodyElement.getBoundingClientRect().height;
    if (editorBodyHeight !== newHeight) {
      editorBodyHeight = newHeight;
      return true;
    }
    return false;
  }

  function handleScroll(): void {
    if (!hasScrolled && !ticking) {
      window.requestAnimationFrame(() => {
        hasScrolled = true;
        ticking = false;
      });
      ticking = true;
    }
  }

  editor.on('init', () => {
    editorBodyElement = editor.getBody();
    editorBodyHeight = editorBodyElement?.getBoundingClientRect()?.height;
    // Add scroll listener to capture when the page has been scrolled
    // This is only done as an optimization to limit the keypress handler
    // from only running after the window has scrolled.
    document.addEventListener('scroll', handleScroll);
  });

  // Remove scroll listener when editor is removed
  editor.on('remove', () => {
    document.removeEventListener('scroll', handleScroll);
  });

  editor.on('keyup', () => {
    // Use a timeout to ensure the browser based scrolling, which
    // occurs when typing in an input, has already occurred.
    setTimeout(() => {
      if (hasScrolled || isHeightOfContentChangedAndUpdate()) {
        handleKeypress(editor, stickyFooter);
        // Reset the scroll flag after we've manually moved the editor.
        hasScrolled = false;
      }
    });
  });
}

/**
 * Filter to convert the style properties to respective attributes on table and its children.
 *
 * @param editor the TinyMce editor.
 */
export function registerTableAttributesFilter(editor: Editor): void {
  /**
   * Helper to convert style properties to respective attributes.
   *
   * @param element the table/tr/td/col/colgroup element.
   * @param styleAttr the style attribute value.
   */
  function applyStyleAttributes(element: HTMLElement, styleAttr: string): void {
    const supportedProperties = new Set(['width', 'height', 'border']);
    styleAttr
      .split(';')
      .map(property => property.trim().toLowerCase().split(':'))
      .filter(property => supportedProperties.has(property[0]))
      .forEach(property => {
        const [propertyKey, propertyValue] = property.map(value => value.trim());
        if (propertyKey && propertyValue) {
          editor.dom.setAttribs(element, { [propertyKey]: propertyValue });
        }
      });
    element.removeAttribute('style');
  }

  function convertAttributesToStyle(element: HTMLElement): void {
    const supportedProperties = new Set(['width', 'height', 'border']);
    Object.keys(element.attributes).forEach(key => {
      const attribute = element.attributes[key];
      if (supportedProperties.has(attribute.name)) {
        element.style[attribute.name] = attribute.value;
        element.removeAttribute(attribute.name);
      }
    });
  }

  editor.on('BeforeExecCommand', ({ command }) => {
    if (command === 'mceTableInsertColBefore' || command === 'mceTableInsertColAfter') {
      editor
        .getBody()
        .querySelectorAll('table col')
        .forEach((element: HTMLTableElement) => {
          convertAttributesToStyle(element);
        });
    }
  });

  editor.on('preInit', () => {
    editor.serializer.addNodeFilter('table', () => {
      editor
        .getBody()
        .querySelectorAll('table')
        .forEach((element: HTMLTableElement) => {
          const styleAttr = element.getAttribute('style');
          if (styleAttr) {
            applyStyleAttributes(element, styleAttr);
          }
          // Process child elements' style attributes
          element.querySelectorAll('*').forEach((childElement: HTMLElement) => {
            const childStyleAttr = childElement.getAttribute('style');
            if (childStyleAttr) {
              applyStyleAttributes(childElement, childStyleAttr);
            }
          });
        });
    });
  });
}

/**
 * Inserts the content along with a blank paragraph, unless one already exists after the current position
 */
function insertContentWithParagraph(activeEditor: Editor, htmlContent: string): void {
  const currentSelection = activeEditor.selection.getNode().closest('FIGURE');
  if (currentSelection) {
    currentSelection.insertAdjacentHTML('afterend', htmlContent);
  } else {
    activeEditor.insertContent(`${htmlContent}<p></p>`);
    const emptyPara = activeEditor.selection.getNode();
    if (emptyPara.nextElementSibling?.nodeName === 'P') {
      activeEditor.selection.setCursorLocation(emptyPara.nextElementSibling, 0);
      emptyPara.remove();
    }
  }
}

/**
 * Ensures the existence of a paragraph after a specified element.
 * Creates a blank paragraph unless one already exists after the current position.
 * In cases where an empty paragraph is present and is immediately followed by another paragraph,
 * the function removes the redundant empty paragraph and sets the cursor location on next element.
 *
 * @param activeEditor the active editor.
 * @param currentElement the element after which existence of a paragraph is checked.
 * @returns void
 */
function ensureNextParagraphExists(activeEditor: Editor, currentElement: Element): void {
  const nextElement = currentElement.nextElementSibling;
  if (
    nextElement.innerHTML.trim().toLowerCase() === '&nbsp;' &&
    nextElement.nextElementSibling?.nodeName === 'P'
  ) {
    currentElement.nextElementSibling.remove();
  }
  if (nextElement.nodeName !== 'P') {
    currentElement.after(activeEditor.dom.create('p', {}, '&nbsp;'));
  }
  activeEditor.selection.setCursorLocation(currentElement.nextElementSibling, 0);
}

/**
 * Adds wrapper div around table elements to make tables responsive.
 *
 * @param element the table element.
 * @param cx loading styles.
 */
export function addTableWrapperHelper(element: HTMLElement, cx: ClassNamesFnWrapper): void {
  const hasTableWrapper = element.closest(`div.${cx('lia-table-wrapper')}`);
  if (!hasTableWrapper) {
    const wrapperElement = Object.assign(document.createElement('div'), {
      className: cx('lia-table-wrapper table-responsive'),
      innerHTML: element.outerHTML
    });
    element.parentNode.replaceChild(wrapperElement, element);
  }
}

/**
 * Adds wrapper div around table elements to make tables responsive.
 *
 * @param editor the TinyMce editor.
 * @param cx loading styles.
 */
export function registerTableWrapper(editor: Editor, cx: ClassNamesFnWrapper): void {
  editor.on('preInit', () => {
    editor.parser.addNodeFilter('table', () => {
      setTimeout(() => {
        editor
          .getBody()
          .querySelectorAll('table')
          .forEach(element => {
            addTableWrapperHelper(element, cx);
          });
      });
    });
  });
}

/**
 * Adds security measures on iframe and wrapper around it to make it non-editable.
 *
 * @param editor the TinyMce editor.
 */
export function registerIFrameWrapper(editor: Editor): void {
  editor.on('preInit', () => {
    editor.parser.addNodeFilter('iframe', (nodes: AstNode[]): void => {
      for (const node of nodes) {
        // LIA-89985 Restrict all iframes
        // Forms allowed as per INFOSEC-4774
        node.attr('sandbox', 'allow-scripts allow-same-origin allow-forms');
        // Tinymce wraps unwrapped iframes with <p> which is editable.
        const hasParaWrapper = node.parent.name === 'p';
        if (hasParaWrapper) {
          // Replace with non-editable <div> wrapper
          const iframeWrapper = window.tinymce.html.Node.create('div', {
            contenteditable: 'false',
            class: 'lia-embeded-content'
          });
          node.parent.replace(iframeWrapper);
          iframeWrapper.append(node);
        }
      }
    });
  });
}

/**
 * Apply a registered format on the current element and trigger node change event
 *
 * @param editor the TinyMce editor.
 * @param name the format to apply.
 */
export function applyFormat(editor: Editor, name: string): void {
  editor.formatter.apply(name);
  editor.nodeChanged();
}

/**
 *  Get the TinyMce node to replace the current node with.
 *
 * @param template the template to create the node from.
 * @param editor the TinyMce editor.
 */
export function getNodeToReplace(template: string, editor: Editor): AstNode {
  const domParser = window.tinymce.html.DomParser(
    editor.editorManager.defaultOptions,
    editor.schema
  );
  return domParser.parse(template).firstChild;
}

/**
 * Get the HTML element by serializing the node.
 *
 * @param editor the TinyMCE Editor.
 * @param node the TinyMCE Node.
 */
export function getHtmlElement(editor: Editor, node: AstNode): HTMLElement {
  const serializer = window.tinymce.html.Serializer(
    editor.editorManager.defaultOptions,
    editor.schema
  );
  const placeholder = document.createElement('div');
  placeholder.innerHTML = serializer.serialize(node);
  return placeholder.firstElementChild as HTMLElement;
}

/**
 * Checks whether the initial and final message form data is similar.
 * This compares the html fields and non html fields separately.
 * Html fields - uses dom parser to compare the content as nodes.
 * non html fields - as string contents - the usual isEqual.
 *
 * @param initialFormData the intial message editor form data.
 * @param finalFormData the final message editor form data.
 * @param htmlFields the fields that needs to be compared as html content.
 */
export function isSimilarData(
  initialFormData: MessageEditorFormData,
  finalFormData: MessageEditorFormData,
  htmlFields: Set<MessageEditorFormField>
): boolean {
  const parser = new DOMParser();

  for (const field in initialFormData) {
    if (htmlFields.has(<MessageEditorFormField>field)) {
      const initialContent = parser.parseFromString(initialFormData[field], 'text/html');
      const finalContent = parser.parseFromString(finalFormData[field], 'text/html');
      if (!initialContent.isEqualNode(finalContent)) {
        return false;
      }
    } else if (!isEqual(initialFormData[field], finalFormData[field])) {
      return false;
    }
  }
  return true;
}

export {
  setCursorAtEndOfNode,
  insertContentAtEndOfEditor,
  insertContentWithParagraph,
  ensureNextParagraphExists
};
