import type { FormattedMessageWithValues } from '../../components/i18n/types';

class FileOption {
  /**
   * The name of the file.
   */
  name?: string;

  /**
   * The size of the file.
   */
  size?: number;

  /**
   * The content type of the file.
   */
  type?: string;

  /**
   * Validation errors to display for this file.
   */
  errors?: FormattedMessageWithValues[];

  constructor(name?: string, size?: number, type?: string) {
    this.name = name;
    this.size = size;
    this.type = type;
  }

  /**
   * Set errors on this option.
   *
   * @param errors errors
   */
  setErrors(errors: FormattedMessageWithValues[]): FileOption {
    this.errors = errors;
    return this;
  }

  /**
   * Does this option have errors?
   */
  hasErrors(): boolean {
    return !!this.errors && this.errors.length > 0;
  }
}

export default FileOption;
